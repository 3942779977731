body {
  font-family: "Roboto", Geneva, Tahoma, sans-serif;
}

.bg-dark {
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe);
}

.sub-menu .nav-link {
  padding-left: 16px !important;
}

.bg-dark .nav-link.active {
  background-color: rgb(255, 255, 255, 0.5);
}

.icon-light {
  color: #ededed;
}

.row-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

input[type="checkbox"] {
  min-height: auto;
  width: 1.25rem;
  height: 1.25rem;
  background-color: rgb(255, 255, 255, 0.5);
}

.box-shadow {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

.css-13cymwt-control {
  background-color: #fff;
  border-color: #dee2e6 !important;
  border-radius: 5px !important;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}
